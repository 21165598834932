import React from 'react';
import "./header.scss";

function Header() {
    return (
        <section id='home'>
            <div className='w-full h-screen flex items-center justify-center px-4 md:px-24' data-aos="fade-up"
        data-aos-duration="1500">
                <div className="flex flex-col md:flex-row items-center justify-between gap-8 md:gap-0">
                    <div className='flex flex-col items-center md:items-start gap-5 text-center md:text-left'>
                        <h1 className='text-4xl md:text-8xl font-bold'>
                            Cabinet <span className='text-green-500 underline-offset-2 underline py-1'>ACTe</span>
                        </h1>
                        <h3 className='text-2xl md:text-4xl'>
                            Agence de Conseil en Management et Technologie
                        </h3>
                        <p className='text-lg md:text-xl'>
                            Expertise comptable, fiscale, RH, marketing et technologie
                        </p>
                        <div>
                            {/* <button></button> */}
                        </div>
                    </div>
                    <div className='w-full md:w-auto'>
                        <img src={require('../img/girl.png')} className='w-full md:w-auto' alt="Girl illustration" />
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Header;
