import React, { useEffect, useState } from "react";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../lib/firebase";
import profileDefault from "../img/profile-default.svg"
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectCards} from 'swiper/modules';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-cards';
const Testimony = () => {
  const [testimonies, setTestimonies] = useState([]);


  
  useEffect(() => {
    const fetchTestimonies = async () => {
      const testimonyCollection = collection(db, "testimonies");
      const testimonySnapshot = await getDocs(testimonyCollection);
      const testimonyList = testimonySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setTestimonies(testimonyList);
    };

    fetchTestimonies();
  }, []);

  return (
   <div className="flex items-center justify-center p-14">
      <Swiper
    className=" w-96"
      /* spaceBetween={50}
      slidesPerView={3} */
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: true,
      }}
      grabCursor={true}
      modules={[EffectCards]} effect="cards"
      
    >
      {testimonies.map((testimony) => (
        <SwiperSlide key={testimony.id} >
             <div  className="card w-full max-w-md h-auto bg-white shadow-xl rounded-lg p-6">
          <div className="card-body items-center text-center">
            <div className="avatar">
              <div className="w-24 rounded-full ring ring-primary ring-offset-base-100 ring-offset-2">
                <img
                  src={testimony.photoURL?? profileDefault}
                  alt="User Avatar"
                />
              </div>
            </div>
            <h2 className="card-title mt-4">{testimony.name}</h2>
            <p className="text-gray-600 italic">
              "{testimony.testimony}"
            </p>
            <div className="rating mt-4">
              {Array.from({ length: 5 }).map((_, index) => (
                <input
                  key={index}
                  type="radio"
                  name={`rating-${testimony.id}`}
                  className="mask mask-star"
                  checked={index < testimony.rating}
                  readOnly
                />
              ))}
            </div>
          </div>
        </div>
        </SwiperSlide>
       
      ))}
    </Swiper>
   </div>
  );
};

export default Testimony;

