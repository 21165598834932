import React from 'react'
import { LuLoader } from 'react-icons/lu'

function Loading() {
  return (
    <div className='flex justify-center items-center h-screen'>
        <div>
            <LuLoader size={40} className=' loading-spinner text-primary'/>
        </div>
    </div>
  )
}

export default Loading