import React from 'react'
import joinUsIcon from "../img/undraw_join_re_w1lh.svg"

function JoinUs() {
    return (
        <section id='joinus' data-aos="fade-up"
            data-aos-duration="1500" className='flex justify-center items-center'>
            <div className="w-3/4">
                <div className="flex md:flex-row flex-col items-center justify-between p-8 gap-12">
                    <img src={joinUsIcon} alt="Join us" className=' w-80' data-aos="fade-left"
                        data-aos-duration="1500" />
                    <div className='flex flex-col mb-5 gap-5' data-aos="fade-right"
                        data-aos-duration="2000">
                        <p>
                            Découvrez nos formations de pointe pour développer vos compétences et booster votre carrière ! Inscrivez-vous dès maintenant et accédez à des contenus exclusifs, des formateurs experts et un accompagnement personnalisé. Ne manquez pas cette opportunité de transformer votre avenir. Rejoignez-nous pour commencer votre parcours vers la réussite !
                        </p>
                        <button className='btn btn-primary' data-aos="fade-up"
                            data-aos-duration="2500">Inscrivez-vous maintenant</button>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default JoinUs