import React from 'react';
import illusSvg from '../img/undraw_teaching_re_g7e3.svg';

function AboutUs() {
    return (
        <section id='about'>
            <div data-aos="fade-up"
                data-aos-duration="1500" className='flex items-center justify-center py-10 bg-white px-4'>
                <div className="grid grid-cols-1 md:grid-cols-2 items-center w-full md:w-3/4 gap-6 md:gap-12">
                    <div data-aos="fade-left"
                        data-aos-duration="2000">
                        <img src={illusSvg} className='w-full' alt="Teaching Illustration" />
                    </div>
                    <div data-aos="fade-up"
                        data-aos-duration="2500">
                        <h1 className='text-xl md:text-2xl font-bold mb-4'>
                            A propos de nous
                        </h1>
                        <p className='text-sm md:text-base'>
                            ACTe, Agence de Conseil en Management et Technologie, est un
                            cabinet d’expertise en management (comptable, fiscale, markéting
                            et Ressources Humaines) et technologie. Le cabinet ACTe est au
                            cœur du renforcement de capacités des jeunes diplômés, en leur
                            offrant des formations qualifiés et répondant aux exigences
                            actuelles du marché de {"l'emploi"}. Aussi, il offre une gamme
                            de prestations et de services dédiés aux professionnels,
                            étudiants et aux entreprises. Il intervient auprès des Petites
                            et Moyennes Entreprises (PME) dans les domaines de la
                            comptabilité, du conseil, de l’audit et dans la mise en place
                            d’outils de pilotage utiles à une bonne gestion de leur
                            entreprise.
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default AboutUs;
