import React from 'react'
import Header from '../components/Header'
import Features from '../components/Features'
import AboutUs from '../components/AboutUs'
import "./home.css"
import JoinUs from '../components/JoinUs'
import Testimony from '../components/Testimony'
function Home() {
  return (
    <div>
        <Header/>
        <AboutUs/>
        <Testimony/>
        <Features/>
        <JoinUs/>
    </div>
  )
}

export default Home