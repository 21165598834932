import React from 'react'
import CardComponent from '../components/CardComponent'
import { LuImagePlus } from 'react-icons/lu'

function Blog() {
    return (
        <div className='flex justify-center items-center py-12'>
            <div className='w-3/4'>
                <div className="flex items-center justify-between">
                    <h1 className='text-5xl font-bold mb-5'>
                        Articles
                    </h1>
                    <button onClick={() => document.getElementById('addArticle').showModal()} className='btn text-white bg-green-500 hover:bg-green-600'>
                        Créer un article
                    </button>
                </div>
                <div className="grid xl:grid-cols-4 gap-3 md:grid-cols-3">
                    <CardComponent />
                    <CardComponent />
                    <CardComponent />
                    <CardComponent />
                    <CardComponent />
                </div>
            </div>

            <dialog id="addArticle" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-lg">Créer un article</h3>
                    <div className="py-4">
                        <form action="" method="post">
                            <div className='mb-3'>
                                <div class="label">
                                    <label htmlFor='title' class="label-text">Titre de l'article</label>
                                </div>
                                <input type="text" id='title' placeholder="" class="input input-bordered w-full" />
                            </div>
                            <div className='mb-3 flex flex-col relative'>
                                <label htmlFor="image">Miniature de l'article</label>
                                <input type="file" className=' z-10 appearance-none opacity-0 w-full h-24 file:opacity-0' />
                                <LuImagePlus className='absolute top-7 -z-10 left-1/3' size={80}/>
                            </div>
                            <div>
                                <textarea
                                    placeholder="Bio"
                                    className="textarea textarea-bordered textarea-lg w-full "></textarea>
                            </div>
                        </form>
                    </div>
                    <div className="modal-action">

                        <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button className="btn">Fermer</button>
                        </form>
                        <button className='btn bg-green-500 hover:bg-green-700 text-white'>Publier</button>
                    </div>
                </div>
            </dialog>
        </div>
    )
}

export default Blog