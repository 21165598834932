import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//import ReactDOM from 'react-dom';
import './navbar.scss'; // Assuming you have a separate CSS/SCSS file for custom styles
import { LuMenu, LuX } from 'react-icons/lu';

function Navbar() {
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.pageYOffset > 90) {
        document.querySelector("nav").classList.add("fixed", "top-0", "transition-all", "duration-300", "w-full", "bg-white", "shadow-lg");
      } else {
        document.querySelector("nav").classList.remove("fixed", "top-0", "transition-all", "duration-300", "w-full", "bg-white", "shadow-lg");
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const loginButton = (
    sessionStorage.getItem("user")?(
      <Link to={'/testimony'} className='btn btn-primary '>
      Laisser un Témoignage
    </Link>
    ):(
      <Link to={'/login'} className='btn btn-primary '>
      Connexion
    </Link>
    )
  );

  return (
    <>
      <nav className='flex items-center backdrop-blur-md justify-between p-3 z-50 bg-transparent'>
        <div>
          <img src={require("../img/logo.png")} className='w-12 h-12 rounded-full' alt="Logo" />
        </div>
        <ul className='hidden md:flex items-center gap-5 px-5'>
          <li>
            <Link to={'/'}>Acceuil</Link>
          </li>
          <li>
            <a href='#about'>A propos de nous</a>
          </li>
          <li>
            <a href='#formations'>Formations</a>
          </li>
          <li>
            <Link to={'/#blog'}>Blog</Link>
          </li>
          <li>
            <a href='/#joinus'>Nous rejoindre</a>
          </li>
        </ul>
        <div className='hidden md:block'>
          {loginButton}
        </div>
        <div className='md:hidden'>
          <button onClick={toggleMenu} className='p-2 rounded-lg text-white bg-green-500'>
            <LuMenu/>
          </button>
        </div>
      </nav>
      <div className={`fixed top-0 left-0 w-3/4 max-w-xs h-full bg-white z-40 transform ${menuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
        <button onClick={toggleMenu} className='p-2 m-4 text-black'>
          <LuX/>
        </button>
        <ul className='flex flex-col items-start p-4'>
          <li>
            <Link to={'/'}>Acceuil</Link>
          </li>
          <li>
            <a href='#about'>A propos de nous</a>
          </li>
          <li>
            <a href='#formations'>Formations</a>
          </li>
          <li>
            <Link to={'/#blog'}>Blog</Link>
          </li>
          <li>
            <a href='/#joinus'>Nous rejoindre</a>
          </li>
          <li>
            {loginButton}
          </li>
        </ul>
      </div>
    </>
  );
}

export default Navbar;
