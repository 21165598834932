import React, { useState } from "react";
import { Link } from "react-router-dom";
import { signInWithFirebase } from "../lib/auth";
import { toast } from "react-toastify";
import { isValide } from "../lib/validation";
import { signInWithGoogle } from "../lib/loginWithGoogle";

const Login = () => {
    const [user,setUserData] = useState({
        email:"",
        password:"",
    })

    /**
     * 
     * @param {Event} e 
     */
    function onChange(e){
        e.preventDefault()
        setUserData((prevData)=>({
            ...prevData,
            [e.target.id]: e.target.value
        }))
    }
    /**
     * 
     * @param {Event} e 
     */
    function onSubmit(e){
        e.preventDefault()
        console.log(isValide(user))
        if (isValide(user)) {
            
            signInWithFirebase(user.email,user.password)&&toast.error("Une erreur s'est produite")
        }else{
            return toast.warning("Veuillez renseigner tous les champs!")
        }
    }
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="card w-full max-w-sm bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold text-center mb-4">Se connecter</h2>
        <form method="post" onSubmit={onSubmit}>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              id="email"
              onChange={onChange}
              placeholder="Votre email"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Mot de passe</span>
            </label>
            <input
              type="password"
              id="password"
              onChange={onChange}
              placeholder="Votre mot de passe"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="flex justify-between items-center mt-4">
            <label className="label cursor-pointer">
              <input type="checkbox" className="checkbox checkbox-primary" />
              <span className="label-text ml-2">Se souvenir de moi</span>
            </label>
            <a href="#/" className="text-sm text-primary">Mot de passe oublié ?</a>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-full mt-6"
          >
            Se connecter
          </button>

          <button
            type="button"
            onClick={signInWithGoogle}
            className="btn btn-white w-full mt-6"
          >
            <img src={require("../img/logoGoogle.png")} className="w-12" alt="" />
            Se connecter avec google
          </button>
        </form>
        <p className="text-center text-sm mt-4">
          Pas encore de compte ? <Link to="/register" className="text-primary">S'inscrire</Link>
        </p>
      </div>
    </div>
  );
};

export default Login;
