import React from 'react';
import './feature.scss';
import { LuBadgeCheck } from 'react-icons/lu';

function Features() {
  return (
    <section id='formations' className='flex items-center justify-center flex-col p-4 md:p-8'>
      <div className='w-full md:w-4/5 px-4 md:px-8 py-8 md:py-12 bg-white mt-8 md:mt-12 rounded-lg'>
        <div className='flex flex-col items-center justify-center'>
          <h1 className='text-center font-bold text-2xl md:text-4xl relative after:absolute after:w-full after:-bottom-2 after:right-0 after:border-2 after:border-green-500 text-green-500 uppercase'>
            Nos formations
          </h1>
          <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 items-start gap-4 md:gap-6 mt-6 md:mt-8'>
            {formations.map((formation, k) => (
              <div key={k} className='w-full bg-white p-4 md:p-8 hover:shadow-lg duration-500 shadow border rounded-lg'>
                <h1 className='text-center font-bold '>{formation.title}</h1>
                <ul className='flex flex-col gap-2 p-3'>
                  {formation.services.map((s, sK) => (
                    <li key={sK} className='flex items-center gap-3'>
                      <LuBadgeCheck className='flex-none' />{s}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}

const formations = [
  {
    title: "INFORMATIQUE ET LOGICIEL",
    services: [
      "Informatique Avancée",
      "Logiciel de gestion comptable",
      'Logiciel de gestion commercial et Paie',
      "commercial et RH",
      "Base de données",
      "Intelligence artificielle",
      "SIG",
      "ArchiCAD",
      "AutoCAD",
      "ADOBE (Photoshop - Adobe Illustrator - Première Pro)",
      "SPSS",
      "AMOS",
      "Nvivo",
      "Iramuteq",
      "Google Sheet",
    ],
  },
  {
    title: "MANAGEMENT ET SOFT SKILLS",
    services: [
      "Expertise comptable",
      "Fiscalité",
      "Communication et Markéting Digital",
      "Communication orale",
      "Management des équipes",
      "Ressources Humaines",
      "Gestion de projet",
      "Transport et Logistique",
      "Agro-Business",
      "Développement personnel",
    ],
  },
  {
    title: "DIGITAL-MULTIMEDIA ET ENTREPRENEURIAT",
    services: [
      "Entrepreneuriat digital",
      "Photographie",
      "Vidéographie",
      "Réseaux sociaux et Publicité professionnelle (Facebook, Tiktok, Instagram, Twitter, LinkedIn, ...)",
      "3D et animations",
      "E-commerce",
      "Création de contenus",
    ],
  },
  {
    title: "GESTION ADMINISTRATIVE",
    services: [
      "Secrétaire Comptable et Caisse",
      "Secrétariat",
      "Secrétaire Pharmacie et médical",
      "Secrétaire Juridique",
      "Logiciels Bureautique",
    ],
  },
  {
    title: "DEVELOPPEMENT D’APPLICATION",
    services: [
      "Création des applications sur mesure",
      "Conception d'application Web, Mobile et Desktop (Cross platform)",
    ],
  },
];

export default Features;
