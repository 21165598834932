import React from 'react'
import Navbar from '../Navbar'
import '../../index.css';
import { Outlet } from 'react-router-dom'

function Layout() {
  return (
    <main>
      <Navbar/>
      <div>
        <Outlet/>
      </div>
    </main>
  )
}

export default Layout