import React from 'react'

function Page404() {
  return (
    <div className='py-12'>
        <h1 className='text-4xl font-bold text-center'>
            Page non trouvée ou en maintenance
        </h1>
    </div>
  )
}

export default Page404