import React, { useState } from "react";
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { toast } from "react-toastify";
import { isValide } from "../lib/validation";
import { db } from "../lib/firebase";
import Loading from "../components/Loading";


const Testimony = () => {
    const user = JSON.parse(sessionStorage.getItem("user"))
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({
        name: user?.displayName ?? "",
        email: user?.email ?? "",
        testimony: "",
        rating: 5,
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleRatingChange = (rating) => {
        setFormData({
            ...formData,
            rating,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isValide(formData)) {
            setLoading(true)
            try {
                await addDoc(collection(db, "testimonies"), {
                    ...formData,
                    user_uid: user.uid,
                    photoURL: user?.photoURL??null,
                    timestamp: serverTimestamp()
                });
                toast.success("Merci pour votre témoignage")
                window.location.href = "/";
                
            } catch (error) {
                setLoading(false)
                toast.error("Votre témoignage n'a pas pu être envoyé")
            }
        } else {
            toast.warning("Faut remplir tous les champs")
        }
    };

    return loading ? <Loading /> : (
        <div className="flex justify-center items-center min-h-screen bg-gray-100">
            <div className="card w-full max-w-lg bg-white shadow-lg rounded-lg p-6">
                <h2 className="text-2xl font-bold text-center mb-6">Ajouter un témoignage</h2>
                <form onSubmit={handleSubmit}>
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Nom complet</span>
                        </label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Votre nom"
                            className="input input-bordered w-full"
                            value={formData.name}
                            readOnly={formData.name !== ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Email</span>
                        </label>
                        <input
                            type="email"
                            name="email"
                            placeholder="Votre email"
                            className="input input-bordered w-full"
                            value={formData.email}
                            readOnly={formData.email !== ""}
                            onChange={handleChange}
                            required
                        />
                    </div>
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Témoignage</span>
                        </label>
                        <textarea
                            name="testimony"
                            placeholder="Votre avis sur nos services"
                            className="textarea textarea-bordered w-full"
                            value={formData.testimony}
                            onChange={handleChange}
                            required
                        ></textarea>
                    </div>
                    <div className="form-control w-full mb-4">
                        <label className="label">
                            <span className="label-text">Note</span>
                        </label>
                        <div className="rating">
                            {[1, 2, 3, 4, 5].map((star) => (
                                <input
                                    key={star}
                                    type="radio"
                                    name="rating"
                                    className="mask mask-star"
                                    value={star}
                                    onChange={() => handleRatingChange(star)}
                                    checked={formData.rating === star}
                                />
                            ))}
                        </div>
                    </div>
                    <button type="submit" className="btn btn-primary w-full mt-4">
                        Soumettre
                    </button>
                </form>
            </div>
        </div>
    );
};

export default Testimony;
