import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { app, db } from "./firebase"; // Assurez-vous d'importer l'instance de Firestore
import { doc, getDoc, serverTimestamp, setDoc } from "firebase/firestore";

const provider = new GoogleAuthProvider();
const auth = getAuth(app);

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider)
    .then(async (result) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      const credential = GoogleAuthProvider.credentialFromResult(result);
      const token = credential.accessToken;

      // The signed-in user info.
      const user = result.user;

      // Stocker le token et l'utilisateur dans sessionStorage
      sessionStorage.setItem("token", token);
      sessionStorage.setItem("user", JSON.stringify(user));

      // Check for user
      const docRef = doc(db, 'users', user.uid)
      const docSnap = await getDoc(docRef)

      // If user, doesn't exist, create user
      if (!docSnap.exists()) {
        await setDoc(doc(db, 'users', user.uid), {
          name: user.displayName,
          email: user.email,
          timestamp: serverTimestamp(),
        })
      }

      window.location.href = "/";
    })
    .catch((error) => {
      // Handle Errors here.
      /* const errorCode = error.code;
      const errorMessage = error.message;
      const email = error.email;
      const credential = GoogleAuthProvider.credentialFromError(error); */
      console.error(error);
    });
};