import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./components/layout/Layout";
import Home from "./pages/Home";
import Page404 from "./pages/Page404";
import Blog from "./pages/Blog";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Testimony from "./pages/Testimony";
import PrivateRoute from "./components/PrivateRoute";


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout/>}>
         <Route path="" element={<Home/>}/>
         <Route path="login" element={<Login/>}/>
         <Route element={<PrivateRoute/>}>
           <Route path="testimony" element={<Testimony/>}/>
         </Route>
         <Route path="register" element={<Register/>}/>
         <Route path="*" element={<Page404/>}/>
        </Route>
        <Route path="/blog" element={<Layout/>}>
          <Route path="" element={<Blog/>}/>
        </Route>
        
      </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;
