import { createUserWithFirebase } from "../lib/auth";
import { isValide } from "../lib/validation";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { signInWithGoogle } from "../lib/loginWithGoogle";

const Register = () => {

    const [user,setUserData] = useState({
        fullname:"",
        email:"",
        password:"",
        confirm_pwd:""
    })

    /**
     * 
     * @param {Event} e 
     */
    function onChange(e){
        e.preventDefault()
        setUserData((prevData)=>({
            ...prevData,
            [e.target.id]: e.target.value
        }))
    }
    /**
     * 
     * @param {Event} e 
     */
    function onSubmit(e){
        e.preventDefault()
        console.log(isValide(user))
        if (isValide(user)) {
            const {confirm_pwd,...data} = user
            if (confirm_pwd !==data.password) {
                return toast.warning("Les mots de passe ne sont pas identiques")
            }
            if(createUserWithFirebase(data.email,data.password,data.fullname))toast.error("Une erreur s'est produite")
        }else{
            return toast.warning("Veuillez renseigner tous les champs!")
        }
    }
  return (
    <div className="flex justify-center items-center min-h-screen bg-gray-100">
      <div className="card w-full max-w-sm bg-white shadow-lg rounded-lg p-6">
        <h2 className="text-2xl font-bold text-center mb-4">Créer un compte</h2>
        <form method="POST" onSubmit={onSubmit}>
          <div className="form-control w-full max-w-xs">
            <label className="label">
              <span className="label-text">Nom complet</span>
            </label>
            <input
              type="text"
              id="fullname"
              onChange={onChange}
              placeholder="Votre nom complet"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Email</span>
            </label>
            <input
              type="email"
              id="email"
              onChange={onChange}
              placeholder="Votre email"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Mot de passe</span>
            </label>
            <input
            id="password"
            onChange={onChange}
              type="password"
              placeholder="Créer un mot de passe"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="form-control w-full max-w-xs mt-4">
            <label className="label">
              <span className="label-text">Confirmer le mot de passe</span>
            </label>
            <input
            id="confirm_pwd"
            onChange={onChange}
              type="password"
              placeholder="Confirmer votre mot de passe"
              className="input input-bordered w-full max-w-xs"
            />
          </div>
          <div className="flex justify-between items-center mt-4">
            <label className="label cursor-pointer">
              <input type="checkbox" className="checkbox checkbox-primary" />
              <span className="label-text ml-2">
                J'accepte les{" "}
                <a href="#/" className="text-primary">
                  termes et conditions
                </a>
              </span>
            </label>
          </div>
          <button
            type="submit"
            className="btn btn-primary w-full mt-6"
          >
            S'inscrire
          </button>
          <button
          onClick={signInWithGoogle}
            type="button"
            className="btn btn-white w-full mt-6"
          >
            <img src={require("../img/logoGoogle.png")} className="w-12" alt="" />
            Créer avec google
          </button>
        </form>
        <p className="text-center text-sm mt-4">
          Vous avez déjà un compte ? <Link to="/login" className="text-primary">Se connecter</Link>
        </p>
      </div>
    </div>
  );
};

export default Register;
