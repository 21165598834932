import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, updateProfile, signOut } from "firebase/auth";
import { app, db } from "./firebase";
import { doc, serverTimestamp, setDoc } from "firebase/firestore";
import { toast } from "react-toastify";

const auth = getAuth(app);
export function createUserWithFirebase(email, password, fullname) {
  let hasError = false;
  createUserWithEmailAndPassword(auth, email, password)

    .then(async (userCredential) => {
      // Signed up 
      const user = userCredential.user;
      // ...
      updateProfile(auth.currentUser, {
        displayName: fullname,
      })
      const timestamp = serverTimestamp()
      const formData = {
        email, timestamp
      }
      await setDoc(doc(db, 'users', user.uid), formData)
      sessionStorage.setItem("user", JSON.stringify(user))
      window.location.href = "/testimony"
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      hasError = true
      // return {errorCode,errorMessage}
      console.log(errorCode, errorMessage)
      // ..
    });
  return hasError
}

export async function signInWithFirebase(email, password) {
  let hasError = false;
  signInWithEmailAndPassword(auth, email, password)
    .then(async (userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // ...

      sessionStorage.setItem("user", JSON.stringify(user))
      window.location.href = "/testimony"
    })
    .catch((error) => {
      const errorCode = error.code;
      const errorMessage = error.message;
      hasError = true
      // return {errorCode,errorMessage}
      console.log(errorCode, errorMessage)
    });
  return hasError;
}

export function logout() {
  try {
    signOut(auth)
    sessionStorage.removeItem("user")
    sessionStorage.removeItem("token")
    window.location.reload()
  } catch (error) {
    toast.error("Une erreur s'est produite")
  }
}